import { IPasswordReset, IAuthError } from '@sybl/feature-auth-models'
import { signalStore, withState, withHooks } from '@ngrx/signals';
import { withAuthPasswordMethods } from '../selectors';
export const AUTH_PASSWORD_FEATURE_KEY = 'password';

export interface AuthPasswordState {
    passwordAttempts: number;
    passwordLoading: boolean;
    passwordInTimeOut: boolean;
    passwordInTimeOutPending: boolean;
    timeOutSeconds: number;
    lockUntil: number;
    emailSubmitted: boolean;
    wrongPassword: boolean;
    passwordChanged?: boolean;
    passwordResetUserInfo: IPasswordReset;
    passwordErrors: Array<string>;
    confirmPasswordErrors: Array<string>;
    forgotPasswordErrors: IAuthError | null;
    forgotPasswordUsername: string
    forgotPasswordUserId: string;
    forgotPasswordEmail: string
    forgotPasswordAvatar: string;
    passwordResetToken: string
}

const initialState: AuthPasswordState = {
    passwordAttempts: 0,
    passwordLoading: false,
    passwordInTimeOut: false,
    passwordInTimeOutPending: false,
    timeOutSeconds: 0,
    lockUntil: 0,
    emailSubmitted: false,
    wrongPassword: false,
    passwordChanged: false,
    passwordResetUserInfo: {},
    passwordErrors: [],
    confirmPasswordErrors: [],
    forgotPasswordErrors: null,
    forgotPasswordUsername: '',
    forgotPasswordEmail:'',
    forgotPasswordUserId: '',
    forgotPasswordAvatar: '',
    passwordResetToken:'',
}

export const AuthPasswordStore = signalStore(
    { providedIn: 'root' },
    withState(initialState),
    withAuthPasswordMethods(),
    withHooks({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onInit(store) {
        //if (store.loadFromLocalStorage()) {
         // return;
       // }
       // store.load(1);
      },
    })
  );

/*
export const reducer = createReducer(// | theseOtherActions.Actions ): State{
    initialState,
    on(AuthResetPasswordActions.PasswordResetAttempt, (state) => {
        return ({
            ...state,
            loading: true
        })
    }
    ),

    on(AuthResetPasswordActions.GetPasswordResetUserDetailsConfirmed,
        (state:any, props) => {
            const user: IAuth = props.user;

            const passwordResetUserInfo = {
                ...state.passwordResetUserInfo,
                forgotPasswordEmail:  user.email,
                forgotPasswordUsername:  user.username,
            }
            
            return ({
                ...state,
                passwordResetUserInfo: passwordResetUserInfo,
                //forgotPasswordAvatar: 'https://s3.amazonaws.com/images.fansay.io/avatars/' +  user.avatar,
                forgotPasswordEmail:  user.email,
                forgotPasswordUsername:  user.username,
                error_message: null
            })
        }
    ),

    on(AuthResetPasswordActions.ConfirmPasswordErrorsUpdate, (state, props) => {
        return ({
            ...state,
            confirmPasswordErrors: props.errors
        })
    }),

    on(AuthChangePasswordActions.ChangePasswordAttempt, (state) => {
        return ({
            ...state,
            loading: true
        })
    }),

    on(AuthResetPasswordActions.PasswordErrorsUpdate,
        (state, props) => {
            return ({
                ...state,
                passwordErrors: props.errors
            })
        }),

    on(AuthResetPasswordActions.ReceivedPasswordTokenFromEmail,
        (state, props) => {
            const token = props.token;

            return ({
                ...state,
                passwordResetToken: token
            })
        }

    ),

    on(AuthResetPasswordActions.ForgotPasswordAttempt, (state, props) => {
        const email = props.email;

        let forgotPasswordUsername = '';
        let forgotPasswordEmail = '';
        let emailSubmitted = false;


        if (email.includes('@')) {
            forgotPasswordEmail = email;
            emailSubmitted = true;

        } else {
            forgotPasswordUsername = email;
            emailSubmitted = false;
        }

        return ({
            ...state,
            loading: true,
            forgotPasswordEmail,
            forgotPasswordUsername,
            //emailSubmitted is only realy used to format forgotPasswor confirmed...
            emailSubmitted: emailSubmitted
        })
    }),

    on(AuthChangePasswordActions.ChangePasswordSuccess, (state) => {
        return ({
            ...state,
            passwordChanged: true,
            loading: false
        })
    }),

    on(AuthChangePasswordActions.ChangePasswordFailed, (state, props) => {
        const error = props.error;
        console.error(error)
        let inTimeOut = false;

        if (state.attempts >= 2) {
            inTimeOut = true;
        }

        const newChangePasswordTries = state.attempts + 1;

        return {
            ...state,
            inTimeOut: inTimeOut,
            attempts: newChangePasswordTries,
            loading: false
        }
    }),

    on(AuthResetPasswordActions.ForgotPasswordFailed, (state) => {
        let inTimeOut = false;

        if (state.attempts >= 2) {
            inTimeOut = true;
        }

        const newForgotPasswordTries = state.attempts + 1;
        return ({
            ...state,
            inTimeOut: inTimeOut,
            loading: false,
            attempts: newForgotPasswordTries
        })

    }),

    on(AuthResetPasswordActions.GetPasswordResetUserDetailsFailed, (state, props) => {
        console.warn('Error props', props)
        const passwordResetUserInfo = {
            ...state.passwordResetUserInfo,
        }
        
        return ({
            ...state,
            loading: false,
            forgotPasswordErrors: props.error,
            passwordResetUserInfo: passwordResetUserInfo,
        })
    }),

    on(AuthResetPasswordActions.ForgotPasswordConfirmed, (state:any, props) => {
        const authUser = props.user;

        return ({
            ...state,
            user: authUser,
            forgotPasswordUsername: authUser.username,
            forgotPasswordEmail:authUser.email,
            attempts: 0,
            timeOutSeconds: 0,
            loading: false
        })
    }
    )

)
    export const getAttempts = (state:AuthPasswordState)=>state.attempts;
    export const getLoading = (state:AuthPasswordState)=>state.loading;
    export const getPasswordResetUserInfo = (state:AuthPasswordState)=>state.passwordResetUserInfo;
    export const getPasswordErrors = (state:AuthPasswordState)=>state.passwordErrors;
    export const getConfirmPasswordErrors = (state:AuthPasswordState)=>state.confirmPasswordErrors;

    export const getWrongPassword = (state:AuthPasswordState)=>state.wrongPassword;
    export const getPasswordChanged= (state:AuthPasswordState)=>state.passwordChanged;
*/
import { Component, Input, Inject, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { SVGButtonImageService } from '../../services'
import { MatTooltipModule } from '@angular/material/tooltip';
// Contains matRipple directive for on click
@Component({
    selector:'lib-svg-icon',
    providers: [SVGButtonImageService],
    standalone: true,
    imports: [CommonModule, MatRippleModule, MatTooltipModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template:`
    <div class="svg-icon-holder"
            [matTooltip]="tooltipText"
            matRipple
            [matRippleCentered]="centered"
            [matRippleDisabled]="disabled"
            [matRippleUnbounded]="unbounded"
            [matRippleRadius]="radius"
            [matRippleColor]="rippleColor"
            terminateOnPointerUp="true"
        >
    <img [src]="imageUrl"
        style="width:auto; display:block;"
        [ngStyle]="{'height.em':setIconSize(iconSize)}"
        [ngClass]="{'white':iconColor==='white','british-racing-green-hue':iconColor==='darkGreen', 'dark-grey-hue':iconColor==='darkGrey', 'turquoise-hue':iconColor==='turquoise', 'blue-hue':iconColor==='blue', 'teal-hue':iconColor==='teal', 'dark-yellow':iconColor==='darkYellow', 'orange-hue':iconColor==='orange', 'dark-orange-hue':iconColor==='darkOrange', 'dark-yellow-hue':iconColor==='darkYellow', 'dark-red-hue':iconColor==='darkRed'}"
        >
    </div>
 `,
    styleUrls: [
      '../templates/svgColorTransforms.scss',
        './svg-icon.component.scss'
    ]
})

export class SvgIconComponent{
    @ViewChild(MatRipple) ripple!: MatRipple;

    centered = false;
    disabled = false;
    unbounded = false;
    terminateOnPointerUp = true;
    radius!: 1;
    rippleColor!: string //='grey';

    @Input() color!:string;
    @Input() iconName!: string;
    @Input() size!: string;
    @Input() tooltipTextInput!:string;

    image_Url=''
  sybl_bucket_url = ''
    constructor(
        @Inject('appUrls') appUrls: any
    ){
        this.image_Url  = appUrls.IMAGES_URL;
      this.sybl_bucket_url = appUrls.S3_SYBL_SVGS_BUCKET_URL;
    }

    get iconColor(){
        return this.color;
    }

    get iconSize(){
        return this.size;
    }

    get imageUrl(){
      return this.iconName.includes('new-bucket') ? this.sybl_bucket_url + this.iconName.split('_')[1] : this.image_Url + this.iconName
    }

    get tooltipText(){
        if(this.tooltipTextInput === undefined){
            switch(this.iconName){
                case 'expand': return 'Click to Expand';
                case 'add': return 'Click to Add';
                case 'collapse':  return 'Click to Collapse';
                case 'close':  return 'Click to Close';
                case 'edit_button.svg':  return 'Click to Edit';
                case 'pencil.svg':  return 'Click to Edit';
                case 'remove':  return 'Click to Remove';
                case 'perform': return 'Click to Perform';
                case 'filter':  return 'Click to Filter';
                case 'favorite': return 'Add to Favorites';
                case 'up_arrow': return 'Click to Collapse';
                case 'down_arrow': return 'Click to Expand';
                case 'left_arrow_circle':
                case 'right_arrow_circle': return 'Show More'
                default: return ''
            }
        }else return this.tooltipTextInput;
    }

    setIconSize(size:string){
        switch (size){
            case 'small':
                return 0.75;
            case 'medium':
                return 1;
            case 'large':
                return 1.25
            case 'extra_large':
                return 1.55
            case '2em':
                return 2
            case '2.5em':
                return 2.5
            default:
                return 1
        }
    }
}

import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import {
  patchState,
  signalStoreFeature,
  type,
  withMethods,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { BehaviorSubject, pipe, switchMap, tap, lastValueFrom } from 'rxjs';
import {
  AuthBackendService,
  AuthCookieService,
  BrowserInfoService,
} from '@sybl/feature-auth-data-access';
import { AuthPasswordState } from '../reducers';

import { Router } from '@angular/router';

export function withAuthPasswordMethods() {
  return signalStoreFeature(
    { state: type<AuthPasswordState>() },
    //todoService ad authService after ---> )
    withMethods(
      (
        state,
        authBackendService = inject(AuthBackendService),
        authCookieService = inject(AuthCookieService),
        browserInfo = inject(BrowserInfoService),
        router = inject(Router)
      ) => ({
        async setTempPassForgot(email: string) {
          patchState(state, {
            forgotPasswordUsername: email,
            forgotPasswordEmail: email,
          });

          return;
        },
        async setForgotPasswordAll(email, username, forgotPasswordUserId){
          patchState(state,{
            forgotPasswordEmail: email,
            forgotPasswordUsername: username,
            forgotPasswordUserId: forgotPasswordUserId
        })
        },
        async setForgotPass(email, username){
          patchState(state, {
            forgotPasswordEmail:email,
            forgotPasswordUsername:username,
          })
          return
        },
        async setTempPassToken(token: string) {
            patchState(state, {
              passwordResetToken: token
            });
  
            return;
          },
          async setForgotPasswordErrors(errors: any) {
            patchState(state, {
                forgotPasswordErrors: errors
            });
  
            return;
          },


          
      })
    )
  );
}

import { Inject, Injectable, Input } from '@angular/core';


@Injectable()
export class SVGButtonImageService {
    @Input()color:string | undefined;
    IMAGES_URL: string;
  S3_SYBL_SVGS_BUCKET_URL: string;
    colorTransform: any;
    constructor(
         @Inject('appUrls') appUrls: any
    ){
        this.IMAGES_URL = appUrls.IMAGES_URL;
      this.S3_SYBL_SVGS_BUCKET_URL = appUrls.S3_SYBL_SVGS_BUCKET_URL;
    }

    public statusIconUrl(iconName: string){
        switch(iconName){
            case 'expandButton': return this.IMAGES_URL +  'circle_expand.svg';
            case 'collapseButton': return this.IMAGES_URL +  'circle_collapse.svg';
            case 'closeButton': return this.IMAGES_URL +  'circle_close.svg';
            case 'checkButton': return this.IMAGES_URL +  'circle_check.svg';
            case 'emptyCircle': return this.IMAGES_URL +  'circle_empty.svg';
            case 'editButton': return this.IMAGES_URL + 'edit_button.svg';
            case 'fullCircle': return this.IMAGES_URL +  'circle_full.svg';
            case 'errorButton': return this.IMAGES_URL +  'circle_error.svg';
            case 'filter': return this.IMAGES_URL +  'filter_default.svg';
            case 'flagFull': return this.IMAGES_URL +  'flag_full.svg';
            case 'emptyFlag': return this.IMAGES_URL +  'flag_empty.svg';
            case 'perform': return this.IMAGES_URL + 'retro_mic.svg'
            case 'infoIcon': return this.IMAGES_URL +  'info_icon.svg';
            case 'up_arrow' : return this.IMAGES_URL + 'up_arrow.svg';
            case 'down_arrow' : return this.IMAGES_URL + 'down_arrow.svg';
            case 'left_arrow_circle' : return this.IMAGES_URL + 'left_arrow_circle.svg';
            case 'right_arrow_circle' : return this.IMAGES_URL + 'right_arrow_circle.svg';

            case 'notificationEmpty': return this.IMAGES_URL +  'notification_empty_stroke.svg';
            case 'notificationFull': return this.IMAGES_URL +  'notification_full_stroke.svg';
            case 'favorite': return this.IMAGES_URL + 'favorite.svg'
            default: return '';
        }
    }


}
